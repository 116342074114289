<template>
    <activity-table show-download-button />
</template>

<script>
import ActivityTable from '@/components/activitys/ActivityTable';

export default {
    components: { ActivityTable },
};
</script>
